@charset "UTF-8";

 @font-face {
  font-family: 'fontello';
  src: url(https://job-static.hirist.com/V8/static/media/icon-no.bd674246.eot);
  src: url(https://job-static.hirist.com/V8/static/media/icon-no.bd674246.eot#iefix) format('embedded-opentype'),
       url(https://job-static.hirist.com/V8/static/media/icon-no.31ade559.woff2) format('woff2'),
       url(https://job-static.hirist.com/V8/static/media/icon-no.6501eadb.woff) format('woff'),
       url(https://job-static.hirist.com/V8/static/media/icon-no.dacd235e.ttf) format('truetype'),
       url(https://job-static.hirist.com/V8/static/media/icon-no.0352b92e.svg#icon-no) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'icon-no';
    src: url('../font/icon-no.svg?28079887#icon-no') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-flexibleworkinghours:before { content: '\E701'; } /* '' */
.icon-gift:before { content: '\E702'; } /* '' */
.icon-gym-membership:before { content: '\E703'; } /* '' */
.icon-health-benefits:before { content: '\E704'; } /* '' */
.icon-health-insurance:before { content: '\E705'; } /* '' */
.icon-in-house-concierge-services:before { content: '\E706'; } /* '' */
.icon-in-house-medical-facility:before { content: '\E707'; } /* '' */
.icon-internal-career-mobility:before { content: '\E708'; } /* '' */
.icon-lactation-rooms:before { content: '\E709'; } /* '' */
.icon-leadership-development-programs:before { content: '\E710'; } /* '' */
.icon-library:before { content: '\E711'; } /* '' */
.icon-life-insurance:before { content: '\E712'; } /* '' */
.icon-marriage-and-childbirth-gifts:before { content: '\E713'; } /* '' */
.icon-maternity-and-paternity-leave:before { content: '\E714'; } /* '' */
.icon-medical-checkup:before { content: '\E715'; } /* '' */
.icon-meditation-room:before { content: '\E716'; } /* '' */
.icon-monthly-lunch:before { content: '\E717'; } /* '' */
.icon-on-site-gym:before { content: '\E718'; } /* '' */
.icon-onilne-medical:before { content: '\E719'; } /* '' */
.icon-onsite-prayer:before { content: '\E720'; } /* '' */
.icon-parant-policysvg:before { content: '\E721'; } /* '' */
.icon-parking-space:before { content: '\E722'; } /* '' */
.icon-performance-bonus:before { content: '\E723'; } /* '' */
.icon-personal-accident-insurance:before { content: '\E724'; } /* '' */
.icon-personal-development-and-training:before { content: '\E725'; } /* '' */
.icon-provision-of-snacks:before { content: '\E726'; } /* '' */
.icon-provison-of-food:before { content: '\E727'; } /* '' */
.icon-quarter-party:before { content: '\E728'; } /* '' */
.icon-recreation-and-sports-events:before { content: '\E729'; } /* '' */
.icon-recreational-area:before { content: '\E730'; } /* '' */
.icon-reward-and-recognition:before { content: '\E731'; } /* '' */
.icon-saving:before { content: '\E732'; } /* '' */
.icon-savings-and-retirement-guidance:before { content: '\E733'; } /* '' */
.icon-spouse-health-check-up:before { content: '\E734'; } /* '' */
.icon-summer-flex-schedule-program:before { content: '\E735'; } /* '' */
.icon-taxi:before { content: '\E736'; } /* '' */
.icon-teacoffee-machine:before { content: '\E737'; } /* '' */
.icon-team-outings-and-annual-celebrations:before { content: '\E738'; } /* '' */
.icon-transportation:before { content: '\E739'; } /* '' */
.icon-voluntary-parent-coverage:before { content: '\E740'; } /* '' */
.icon-work-from-home:before { content: '\E741'; } /* '' */
.icon-world-class-projects:before { content: '\E742'; } /* '' */
.icon-5-days-working:before { content: '\E743'; } /* '' */
.icon-24-hour-health-helpline:before { content: '\E744'; } /* '' */
.icon-24-hour-onsite-cafe:before { content: '\E745'; } /* '' */
.icon-24-hour-wellness-center:before { content: '\E746'; } /* '' */
.icon-birthday-and-anniversary-leave:before { content: '\E747'; } /* '' */
.icon-birthday-celebration:before { content: '\E748'; } /* '' */
.icon-cab-service:before { content: '\E749'; } /* '' */
.icon-cafeteria:before { content: '\E750'; } /* '' */
.icon-career_growth:before { content: '\E751'; } /* '' */
.icon-company-car:before { content: '\E752'; } /* '' */
.icon-company-leased-vehicle:before { content: '\E753'; } /* '' */
.icon-continuingeducationsupport:before { content: '\E754'; } /* '' */
.icon-convenienceservices:before { content: '\E755'; } /* '' */
.icon-discount:before { content: '\E756'; } /* '' */
.icon-employeeassistanceprogram:before { content: '\E757'; } /* '' */
.icon-employeefriendlypolicies:before { content: '\E758'; } /* '' */
.icon-esops:before { content: '\E759'; } /* '' */
.icon-esops2:before { content: '\E760'; } /* '' */
.icon-festive-celebrations:before { content: '\E761'; } /* '' */
.icon-work-anniversary-celebration:before { content: '\E762'; } /* '' */
.icon-down_arrow:before { content: '\E763'; } /* '' */
.icon-facebook:before { content: '\E764'; } /* '' */
.icon-facebook_footer:before { content: '\E765'; } /* '' */
.icon-google-plus_footer:before { content: '\E766'; } /* '' */
.icon-home_footer:before { content: '\E767'; } /* '' */
.icon-left_arrow:before { content: '\E768'; } /* '' */
.icon-linkedin:before { content: '\E769'; } /* '' */
.icon-linkedin_footer:before { content: '\E770'; } /* '' */
.icon-minus:before { content: '\E771'; } /* '' */
.icon-outline_plus:before { content: '\E772'; } /* '' */
.icon-outline_minus:before { content: '\E773'; } /* '' */
.icon-photo_view:before { content: '\E774'; } /* '' */
.icon-plus:before { content: '\E775'; } /* '' */
.icon-right_arrow:before { content: '\E776'; } /* '' */
.icon-top_arrow:before { content: '\E777'; } /* '' */
.icon-twitter:before { content: '\E778'; } /* '' */
.icon-twitter_footer:before { content: '\E779'; } /* '' */
.icon-video:before { content: '\E780'; } /* '' */
.icon-youtube_footer:before { content: '\E781'; } /* '' */
.icon-csr:before { content: '\E782'; } /* '' */
.icon-experience:before { content: '\E783'; } /* '' */
.icon-location:before { content: '\E784'; } /* '' */
.icon-cross:before { content: '\E785'; } /* '' */
.icon-plus2:before { content: '\E786'; } /* '' */
.icon-minus2:before { content: '\E787'; } /* '' */
.icon-perk1:before { content: '\E788'; } /* '' */
.icon-perk2:before { content: '\E789'; } /* '' */
.icon-pencil:before { content: '\E790'; } /* '' */
.icon-travel-assistance:before { content: '\E791'; } /* '' */
.icon-paid-sabbatical:before { content: '\E792'; } /* '' */
.icon-greenoffice:before { content: '\E793'; } /* '' */
.icon-gender-diversity:before { content: '\E794'; } /* '' */
.icon-angle-down:before { content: '\E800'; } /* '' */
.icon-down-open:before { content: '\E801'; } /* '' */
.icon-angle-right:before { content: '\E802'; } /* '' */
.icon-more:before { content: '\E803'; } /* '' */
.icon-course:before { content: '\E804'; } /* '' */
.icon-notification:before { content: '\E805'; } /* '' */
.icon-view-all:before { content: '\E806'; } /* '' */
.icon-logout:before { content: '\E807'; } /* '' */
.icon-left-arrow:before { content: '\E808'; } /* '' */
.icon-back:before { content: '\E809'; } /* '' */
.icon-add:before { content: '\E80A'; } /* '' */
.icon-company:before { content: '\E80B'; } /* '' */
.icon-consultant:before { content: '\E80C'; } /* '' */
.icon-login:before { content: '\E80D'; } /* '' */
.icon-premium:before { content: '\E80E'; } /* '' */
.icon-info:before { content: '\E80F'; } /* '' */
.icon-save:before { content: '\E810'; } /* '' */
.icon-tick:before { content: '\E811'; } /* '' */
.icon-showcase:before { content: '\E812'; } /* '' */
.icon-diversity:before { content: '\E813'; } /* '' */
.icon-search-big:before { content: '\E814'; } /* '' */
.icon-close:before { content: '\E815'; } /* '' */
.icon-down-arrow:before { content: '\E816'; } /* '' */
.icon-hemburger:before { content: '\E817'; } /* '' */
.icon-right-arrow:before { content: '\E818'; } /* '' */
.icon-search:before { content: '\E819'; } /* '' */
.icon-sort:before { content: '\E81A'; } /* '' */
.icon-filter:before { content: '\E81B'; } /* '' */
.icon-default-jobfeed:before { content: '\E81C'; } /* '' */
.icon-fallback-img:before { content: '\E81C'; } /* '' */
.icon-information:before { content: '\E81D'; } /* '' */
.icon-done:before { content: '\E81E'; } /* '' */
.icon-method:before { content: '\E820'; } /* '' */
.icon-exp:before { content: '\E821'; } /* '' */
.icon-fee:before { content: '\E822'; } /* '' */
.icon-page-up:before { content: '\E823'; } /* '' */
.icon-nivea:before { content: '\E82C'; } /* '' */
.icon-csr-1:before { content: '\E82D'; } /* '' */
.icon-growth:before { content: '\E82E'; } /* '' */
.icon-partnership-for-prevention:before { content: '\E82F'; } /* '' */
.icon-employee-assistance-program:before { content: '\E830'; } /* '' */
.icon-post_maternity:before { content: '\E831'; } /* '' */
.icon-health_club:before { content: '\E832'; } /* '' */
.icon-birthday:before { content: '\E84C'; } /* '' */
.icon-cab:before { content: '\E84D'; } /* '' */
.icon-cafe:before { content: '\E858'; } /* '' */
.icon-car:before { content: '\E859'; } /* '' */
.icon-car_insurance:before { content: '\E85A'; } /* '' */
.icon-clock:before { content: '\E85B'; } /* '' */
.icon-coffee_cup:before { content: '\E85C'; } /* '' */
.icon-coffee_machine:before { content: '\E85D'; } /* '' */
.icon-conceirge:before { content: '\E85E'; } /* '' */
.icon-dart_arrow:before { content: '\E85F'; } /* '' */
.icon-discounts:before { content: '\E860'; } /* '' */
.icon-doctor:before { content: '\E861'; } /* '' */
.icon-dollars_bills:before { content: '\E862'; } /* '' */
.icon-dumbell:before { content: '\E863'; } /* '' */
.icon-employeefriendly:before { content: '\E864'; } /* '' */
.icon-gymnast_practice:before { content: '\E865'; } /* '' */
.icon-help_the_elderly:before { content: '\E866'; } /* '' */
.icon-helpline:before { content: '\E867'; } /* '' */
.icon-helpline2:before { content: '\E868'; } /* '' */
.icon-higher-ed:before { content: '\E869'; } /* '' */
.icon-ice_cream:before { content: '\E86A'; } /* '' */
.icon-insurance:before { content: '\E86B'; } /* '' */
.icon-library-1:before { content: '\E86C'; } /* '' */
.icon-man_working:before { content: '\E86D'; } /* '' */
.icon-maternity:before { content: '\E86E'; } /* '' */
.icon-medical:before { content: '\E86F'; } /* '' */
.icon-medicalfees:before { content: '\E870'; } /* '' */
.icon-meditation:before { content: '\E871'; } /* '' */
.icon-mortarboard:before { content: '\E872'; } /* '' */
.icon-park:before { content: '\E873'; } /* '' */
.icon-phone:before { content: '\E874'; } /* '' */
.icon-pie_chart:before { content: '\E875'; } /* '' */
.icon-presentation:before { content: '\E876'; } /* '' */
.icon-pulse:before { content: '\E877'; } /* '' */
.icon-rewards:before { content: '\E878'; } /* '' */
.icon-savings:before { content: '\E879'; } /* '' */
.icon-schedule:before { content: '\E87A'; } /* '' */
.icon-voucher:before { content: '\E87B'; } /* '' */
.icon-default:before { content: '\E87C'; } /* '' */
.icon-mobility:before { content: '\E881'; } /* '' */
.icon-projects:before { content: '\E882'; } /* '' */
.icon-sports:before { content: '\E883'; } /* '' */
.icon-training:before { content: '\E884'; } /* '' */
.icon-celebrations:before { content: '\E885'; } /* '' */
.icon-save-white:before { content: '\E8BF'; } /* '' */
.icon-single-arrow:before { content: '\E8C0'; } /* '' */
.icon-double-arrow:before { content: '\E8C1'; } /* '' */
.icon-home:before { content: '\E8C3'; } /* '' */
.icon-profile:before { content: '\E8C4'; } /* '' */
.icon-no-notification:before { content: '\E8C5'; } /* '' */
.icon-insights:before { content: '\E8DD'; } /* '' */
.icon-undo:before { content: '\E8DE'; } /* '' */
.icon-no-messages:before { content: '\E965'; } /* '' */
.icon-clip:before { content: '\E966'; } /* '' */
.icon-no-of-application:before { content: '\E967'; } /* '' */
.icon-no-of-recruiter:before { content: '\E968'; } /* '' */
.icon-save-orange:before { content: '\E969'; } /* '' */
.icon-right-straight-arrow:before { content: '\E970'; } /* '' */
.icon-download-tick:before { content: '\E971'; } /* '' */
.icon-exclusive-design:before { content: '\E972'; } /* '' */
.icon-left-straight-arrow:before { content: '\E973'; } /* '' */
.icon-fresh-job:before { content: '\E974'; } /* '' */
.icon-sad:before { content: '\E975'; } /* '' */
.icon-emerging-technologies-roles:before { content: '\E976'; } /* '' */
.icon-devops-jobs:before { content: '\E977'; } /* '' */
.icon-backend-developer-jobs:before { content: '\E978'; } /* '' */
.icon-frontend-developer-jobs:before { content: '\E979'; } /* '' */
.icon-mobile-applications-jobs:before { content: '\E980'; } /* '' */
.icon-analytics-data-science:before { content: '\E981'; } /* '' */
.icon-no-insights:before { content: '\E982'; } /* '' */
.icon-alert:before { content: '\E983'; } /* '' */
.icon-eye:before { content: '\E984'; } /* '' */
.icon-startup:before { content: '\E985'; } /* '' */
.icon-others:before { content: '\E986'; } /* '' */
.icon-project-managment:before { content: '\E987'; } /* '' */
.icon-semiconductor-vlsi-eda:before { content: '\E988'; } /* '' */
.icon-enterprise-sap-oracle:before { content: '\E989'; } /* '' */
.icon-product-managment:before { content: '\E990'; } /* '' */
.icon-quality-assurance:before { content: '\E991'; } /* '' */
.icon-ui-design:before { content: '\E992'; } /* '' */
.icon-tnc:before { content: '\E993'; } /* '' */
.icon-refund-policy:before { content: '\E994'; } /* '' */
.icon-settings:before { content: '\E995'; } /* '' */
.icon-saved-jobs:before { content: '\E996'; } /* '' */
.icon-removed-jobs:before { content: '\E997'; } /* '' */
.icon-pending-test:before { content: '\E998'; } /* '' */
.icon-setting-info:before { content: '\E999'; } /* '' */
.icon-notifications:before { content: '\E1000'; } /* '\e1000' */
.icon-my-interview:before { content: '\E1001'; } /* '\e1001' */
.icon-applied-jobs:before { content: '\E1002'; } /* '\e1002' */
.icon-chat:before { content: '\E1003'; } /* '\e1003' */
.icon-download:before { content: '\E1004'; } /* '\e1004' */
.icon-duration:before { content: '\E1005'; } /* '\e1005' */
.icon-timer:before { content: '\E1006'; } /* '\e1006' */
.icon-calendar:before { content: '\E1007'; } /* '\e1007' */
.icon-toast-error:before { content: '\E1008'; } /* '\e1008' */
.icon-toast-info:before { content: '\E1009'; } /* '\e1009' */
.icon-toast-success:before { content: '\E1010'; } /* '\e1010' */
.icon-face-to-face:before { content: '\E1011'; } /* '\e1011' */
.icon-call:before { content: '\E1012'; } /* '\e1012' */
.icon-email:before { content: '\E1013'; } /* '\e1013' */
.icon-book-slot:before { content: '\E1014'; } /* '\e1014' */
.icon-video-slot:before { content: '\E1015'; } /* '\e1015' */
.icon-my-chat-link:before { content: '\E1016'; } /* '\e1016' */
.icon-category-latest-jobs:before { content: '\E1017'; } /* '\e1017' */
.icon-hand-pointer:before { content: '\E1018'; } /* '\e1018' */
.icon-profile-projector:before { content: '\E1019'; } /* '\e1019' */
.icon-quote:before { content: '\E1020'; } /* '\e1020' */
.icon-assesment-expired:before { content: '\E1021'; } /* '\e1021' */
.icon-not-authorized:before { content: '\E1022'; } /* '\e1022' */
.icon-attachment:before { content: '\E1023'; } /* '\e1023' */
.hiristTechWrapper {
  top: 0;
  position: fixed;
  height: 80px;
  background-color: #000;
  z-index: 10;
  width: 100vw;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  @media only screen and (max-width: 1023px) {
    .hiristTechWrapper {
      bottom: 0;
      top: initial; } }
  @media only screen and (min-width: 1024px) {
    .hiristTechWrapper {
      height: 60px; } }

.hiristTechFlexWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
  height: 100%; }
  @media only screen and (max-width: 450px) {
    .hiristTechFlexWrapper {
      justify-content: space-between; } }
  @media only screen and (max-width: 450px) {
    .hiristTechFlexWrapper {
      text-align: left; } }

.hiristTechText {
  font-family: Nunito Sans;
  font-size: 17px;
  color: #fff;
  margin-left: 20px;
  line-height: 1.3; }
  .hiristTechText .text-style-1 {
    font-weight: 800; }

.hiristTechCloseIcon {
  width: 14px;
  height: 14px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer; }
  @media only screen and (min-width: 830px) {
    .hiristTechCloseIcon {
      margin-left: 120px; } }

.hiristTechButton {
  min-width: 123px;
  display: inline-block;
  padding: 11px 11px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #e9630c;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer; }

body {
  margin: 0;
  font-size: 14px;
  line-height: 1.1; }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }

img {
  vertical-align: middle;
  border: 0; }

a {
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none !important; }

a:hover {
  color: unset; }

form {
  display: block;
  margin-top: 0em; }

select, textarea {
  font-family: inherit;
  line-height: inherit; }

p {
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px; }

.sidebar-heading {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 13px;
  width: 74%; }

circle {
  transition: stroke-dashoffset 1s;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%; }

.showcase-loader-wrapper {
  position: relative; }
  @media screen and (min-width: 1280px) {
    .showcase-loader-wrapper {
      min-height: 200px; } }
  @media screen and (max-width: 1279px) and (min-width: 769px) {
    .showcase-loader-wrapper {
      min-height: 170px; } }
  @media screen and (max-width: 768px) and (min-width: 426px) {
    .showcase-loader-wrapper {
      min-height: 120px; } }
  @media screen and (max-width: 425px) {
    .showcase-loader-wrapper {
      min-height: 170px; } }

.skeleton {
  background-color: #e2e5eb;
  margin: 0 5px;
  border-radius: 6px; }

.skeleton.text {
  width: 100%;
  height: 19px;
  margin-bottom: 15px; }

.skeleton.title {
  width: 50%;
  height: 19px;
  margin-bottom: 15px; }

.skeleton.avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%; }

.skeleton.thumbnail {
  width: 100px;
  height: 100px; }

.skeleton-wrapper {
  margin: 20px auto;
  padding: 10px 15px;
  border-radius: 6px; }
  .skeleton-wrapper .profile-section {
    display: grid;
    grid-template-columns: 1fr 4fr;
    justify-content: center;
    align-items: center; }
    .skeleton-wrapper .profile-section .profile-img-container {
      display: flex;
      flex-direction: column;
      align-items: center; }
  .skeleton-wrapper .skeleton-ads {
    display: flex;
    justify-content: center;
    align-items: center; }
  .skeleton-wrapper .skeleton-filter {
    display: flex;
    align-items: center; }
  .skeleton-wrapper .skeleton-showcase {
    display: flex;
    align-items: center; }
  .skeleton-wrapper .skeleton-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 10px;
    gap: 10px; }

.skeleton-wrapper.profile-skeleton-container {
  border: solid 1px #ececec;
  cursor: pointer; }
  .skeleton-wrapper.profile-skeleton-container:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.09); }

.jobfeed-shimmer-container {
  padding: 0 20px; }
  .jobfeed-shimmer-container .job-card {
    border: solid 1px #ececec; }
    .jobfeed-shimmer-container .job-card .job-description {
      margin: 0; }
      .jobfeed-shimmer-container .job-card .job-description #shell-logo {
        margin: 0; }
  .jobfeed-shimmer-container .jobfeed-shimmer-left-container {
    display: inline-block;
    width: 70%;
    vertical-align: top; }
    @media screen and (max-width: 1024px) {
      .jobfeed-shimmer-container .jobfeed-shimmer-left-container {
        width: 100%; } }
    .jobfeed-shimmer-container .jobfeed-shimmer-left-container .jobfeed-skeleton-container .jobfeed-skeleton-body {
      margin-top: -50px; }
  .jobfeed-shimmer-container .jobfeed-shimmer-right-container {
    display: inline-block;
    width: 30%;
    vertical-align: top; }
    @media screen and (max-width: 1024px) {
      .jobfeed-shimmer-container .jobfeed-shimmer-right-container {
        display: none; } }

.main-skeleton-container {
  position: relative;
  overflow: hidden;
  background-color: #fff; }

.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: loading 2.5s infinite;
          animation: loading 2.5s infinite;
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05); }

.shimmer {
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  -webkit-transform: skewX(-20deg);
          transform: skewX(-20deg); }

@-webkit-keyframes loading {
  0% {
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%); }
  50% {
    -webkit-transform: translateX(-60%);
            transform: translateX(-60%); }
  100% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); } }

@keyframes loading {
  0% {
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%); }
  50% {
    -webkit-transform: translateX(-60%);
            transform: translateX(-60%); }
  100% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); } }

.lightgreydivider {
  border-bottom: 1px solid #ddd;
  margin: 10px 0; }

.greydivider {
  border-bottom: 1px solid #b6b6b6;
  margin: 10px;
  width: 95%; }

.vertical-divider {
  width: 1px;
  height: 30px;
  background-color: #e9effa; }

.opac-3 {
  opacity: 0.3; }

.opac-7 {
  opacity: 0.7; }

.in-block {
  display: inline-block; }

.job-card {
  width: 100%;
  min-height: 70px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 10px !important;
  position: relative;
  cursor: pointer; }
  .job-card:hover {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.09); }
  @media only screen and (max-width: 425px) {
    .job-card {
      margin-left: -11px; } }

@media all and (width: 768px) {
  .job-card-with-checkbox {
    width: 95%; } }

.job-card:hover .logo-card {
  box-shadow: 0 4px 20px 0 rgba(180, 185, 216, 0.44); }

.job-apply-checkbox {
  position: absolute;
  left: -56px;
  top: 0;
  width: 30px;
  height: 30px; }
  @media all and (max-width: 767px) {
    .job-apply-checkbox {
      left: -51px; } }
  .job-apply-checkbox input[type="checkbox"] {
    display: inline-block;
    vertical-align: top;
    width: auto;
    display: none; }
  .job-apply-checkbox input[type="checkbox"] + label {
    background-image: url("https://job-static.hirist.com/resources/unchecked.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left 2px center;
    cursor: pointer;
    width: 50px;
    height: 90px;
    position: absolute;
    top: -12px; }
  .job-apply-checkbox input[type="checkbox"]:checked + label {
    background-image: url("https://job-static.hirist.com/resources/checked.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left 2px center;
    cursor: pointer; }
  .job-apply-checkbox input[type="checkbox"]:disabled + label {
    background-image: url("https://job-static.hirist.com/resources/unchecked-disabled.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left 2px center;
    cursor: pointer; }
  .job-apply-checkbox input[type="checkbox"]:disabled[applied="true"] + label {
    background-image: url("https://job-static.hirist.com/resources/checked-disabled.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left 2px center;
    cursor: pointer; }
  .job-apply-checkbox label:hover + .set-tooltip {
    display: block;
    width: 300px;
    left: 135px; }
    .job-apply-checkbox label:hover + .set-tooltip i {
      left: 20px; }

.feed-tooltip {
  display: block;
  position: relative;
  font-size: 13px;
  left: auto;
  top: auto;
  margin-left: 200px;
  margin-bottom: 20px; }
  @media all and (max-width: 425px) {
    .feed-tooltip {
      margin-left: 180px;
      width: 352px;
      text-align: left; } }
  .feed-tooltip i {
    top: auto !important;
    bottom: -3px !important;
    left: 19px !important;
    transform: rotate(-45deg) !important;
    -webkit-transform: rotate(-45deg) !important;
    -moz-transform: rotate(-45deg) !important;
    -ms-transform: rotate(-45deg) !important;
    -o-transform: rotate(-45deg) !important; }

.applied-border {
  border: 1px solid #49c5b6; }

.logo-card {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: #56baea;
  position: absolute;
  left: -24px;
  top: 10px; }

.default-job {
  padding: 12px 7px;
  font-size: 26px;
  color: white;
  background: white; }

.logo-image {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: white;
  position: absolute;
  left: -24px;
  top: 10px;
  border: solid 1px #ececec;
  object-fit: contain; }
  .logo-image:hover {
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.19); }

.applied-card {
  width: 50px;
  height: 16px;
  border-radius: 3px;
  background-color: #49c5b6;
  position: absolute;
  font-size: 10px;
  color: white;
  top: -8px;
  left: 42px;
  padding: 3.5px; }

.job-description {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px; }
  @media screen and (max-width: 425px) {
    .job-description {
      padding-right: 7px;
      padding-left: 3px;
      font-size: 14px; } }

.job-title {
  margin-left: 29px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal; }
  .job-title a:hover {
    text-decoration: none;
    color: #000; }
  @media screen and (max-width: 600px) {
    .job-title {
      margin-left: 34px; } }
  @media screen and (max-width: 425px) {
    .job-title {
      margin-top: 6px; }
      .job-title a {
        word-break: break-word; } }

.job-fields {
  margin-left: 26px;
  margin-top: 6px;
  margin-bottom: 11px; }
  @media screen and (max-width: 600px) {
    .job-fields {
      margin-left: 34px; }
      .job-fields .ml0 {
        margin-left: 7px; } }
  @media screen and (min-width: 426px) {
    .job-fields .show-xs {
      display: none; } }
  @media screen and (max-width: 425px) {
    .job-fields .ml0 {
      margin-left: 15px;
      margin-right: 15px; }
    .job-fields .hide-xs {
      display: none; } }
  .job-fields span {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333; }
    @media screen and (max-width: 425px) {
      .job-fields span {
        text-align: left; } }

.job-tags {
  margin-left: 26px;
  margin-top: -5px;
  margin-bottom: 11px; }
  @media screen and (max-width: 600px) {
    .job-tags {
      margin-left: 34px; }
      .job-tags .ml0 {
        margin-left: 7px; } }
  @media screen and (min-width: 426px) {
    .job-tags .show-xs {
      display: none; } }
  @media screen and (max-width: 425px) {
    .job-tags .ml0 {
      margin-left: 15px;
      margin-right: 15px; }
    .job-tags .hide-xs {
      display: none; } }
  .job-tags span {
    font-size: 11px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #6f6f6f; }
    @media screen and (max-width: 425px) {
      .job-tags span {
        text-align: left; } }

.job-info {
  vertical-align: top;
  text-align: right;
  padding-left: 0px; }
  @media only screen and (max-width: 1024px) {
    .job-info {
      margin-left: 0px; } }
  @media only screen and (max-width: 425px) {
    .job-info {
      display: none; } }
  @media screen and (max-width: 600px) {
    .job-info {
      display: none;
      padding-right: 10px;
      padding-left: 0px; } }

.job-info .job-info_more {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 300;
  font-size: 9px;
  text-align: center; }

.circle-around {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1px solid #b4b9d8;
  margin-right: 6px;
  margin-left: 5px;
  display: inline-block;
  padding-top: 6px;
  padding-right: 4px;
  margin-top: 10px;
  transition: all 1s;
  cursor: pointer; }
  @media screen and (max-width: 600px) {
    .circle-around {
      margin-right: 4px;
      margin-left: 1px; } }

.subtitle-button {
  height: 36px;
  border-radius: 18px;
  border: solid 1px #006ed7;
  margin-top: 17px;
  width: 120px;
  float: right;
  margin-right: 25px; }

.more-info-button {
  margin-top: 10px;
  text-align: center; }

.verticaldivider {
  margin: 0 6px; }

.verticaldivider:after {
  content: '';
  width: 0;
  height: 45%;
  border: 0.6px solid #e9effa;
  position: absolute;
  top: 12px; }

.flex {
  display: flex; }

.hover-more {
  width: 36px;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  background-color: #b4b9d8;
  z-index: 1;
  position: absolute;
  right: -30px; }
  .hover-more .hover-i {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  @media only screen and (max-width: 425px) {
    .hover-more {
      right: -15px; } }

.half {
  height: 50%;
  position: relative; }

.half:nth-of-type(2) {
  border-top: 1px solid #fff; }

.half:only-of-type {
  height: 100%; }

@media screen and (max-width: 425px) {
  .original {
    display: none; } }

.alt_bg:nth-of-type(2n) {
  background-color: #f5f6f7; }

.pt4 {
  padding-top: 4px; }

.container {
  max-width: 100%; }

.mt5 {
  margin-top: 5px; }

.mr5 {
  margin-right: 5px; }

.ws {
  white-space: normal;
  overflow: hidden;
  font-weight: 600;
  line-height: 1.5; }

.ol {
  text-align: left;
  list-style-type: square;
  margin-left: 24px;
  font-style: normal;
  font-size: 16px; }

.relative {
  position: relative; }

@media (max-width: 1399px) {
  .diverse {
    padding-right: 10px; } }

@media (min-width: 1440px) {
  .diverse {
    padding-right: 9px; } }

.showcase-logo {
  position: relative; }

.showcase-logo a {
  text-decoration: none; }

.showcase-logo:hover #save_job {
  display: block; }

.set-tooltip i {
  position: absolute;
  top: -2px;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
  background-color: inherit;
  -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
          clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  border-radius: 0 0 0 0.25em; }

.set-tooltip i::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  -webkit-transform: translate(-50%, 50%) rotate(45deg);
          transform: translate(-50%, 50%) rotate(45deg);
  background-color: #1c2d41;
  border: 1px solid #1c2d41; }

#company-job {
  position: relative; }
  @media all and (max-width: 425px) {
    #company-job {
      margin-left: 30px; } }
  #company-job:hover #company_data {
    display: block; }
    @media all and (max-width: 425px) {
      #company-job:hover #company_data {
        display: none; } }

#company_data {
  top: 26px; }

#display_location {
  top: 26px; }

#location:hover #display_location {
  display: block; }
  @media screen and (max-width: 425px) {
    #location:hover #display_location {
      display: none; } }

.showcase:hover #sneak_peak {
  display: block; }

.showcase:hover #binoculars {
  -webkit-filter: invert(26%) sepia(3%) saturate(13%) hue-rotate(314deg) brightness(93%) contrast(82%);
          filter: invert(26%) sepia(3%) saturate(13%) hue-rotate(314deg) brightness(93%) contrast(82%); }

.diverse:hover #explore_diversity {
  display: block; }

.diverse:hover #user {
  -webkit-filter: invert(26%) sepia(3%) saturate(13%) hue-rotate(314deg) brightness(93%) contrast(82%);
          filter: invert(26%) sepia(3%) saturate(13%) hue-rotate(314deg) brightness(93%) contrast(82%); }

#star:hover #star_job {
  display: block; }

#company:hover #company_job {
  display: block; }

#premium:hover #premium_job {
  display: block; }

#diversity:hover #diversity_logo {
  display: block; }

#applied:hover #applied_job {
  display: block; }

.diversity-li {
  padding: 2px 1px; }

.close {
  float: right;
  line-height: 1;
  text-shadow: 0 0;
  opacity: 1;
  font-weight: 400; }

.pointer {
  cursor: pointer; }

@media only screen and (min-width: 768px) and (max-width: 1146px) {
  .mr10 {
    margin-right: 7px; } }

.show-tooltip {
  display: inline-block;
  position: relative; }

.mr {
  margin-right: 12px; }

@media (min-width: 1440px) {
  .mr {
    margin-right: 23px; } }

.pdr5 {
  padding-right: 5px; }

@media screen and (max-width: 425px) {
  .align-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
    width: 75%;
    margin-left: 0px;
    margin-top: 2px; } }

@media screen and (max-width: 320px) {
  .align-title {
    width: 75%; } }

.pb10 {
  padding-bottom: 10px; }

.vertically-center {
  top: 50%;
  -webkit-transform: translate(0% -50%);
          transform: translate(0% -50%); }

.talentBridgeApplyButton {
  background: #e9630c;
  color: #fff;
  padding: 6px 10px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  border: unset;
  margin-top: 18px;
  margin-right: 20px; }
  .talentBridgeApplyButton:focus {
    outline: unset; }

.ph-item {
  border: none;
  background: none; }

.shell-card {
  width: 100%;
  min-height: 135px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  margin-bottom: 10px !important;
  position: relative; }

#shell-btn {
  width: 150px;
  margin-left: 36px;
  height: 31px;
  border-radius: 15.5px; }

.shell-color {
  color: #dce3ed; }

.shell-desc {
  background-color: white !important; }

#shell-logo {
  height: 50px; }

#shell-job-title {
  margin-left: 35px;
  height: 15px;
  margin-top: 10px;
  border-radius: 8px; }

#shell-info {
  margin-left: 35px;
  background-color: white; }

.ph-row .big, .ph-row.big div {
  height: 36px;
  margin-bottom: 15px; }

.ph-row div {
  background-color: #dce3ed; }

.sb {
  width: 23%;
  border-radius: 8px; }
  @media only screen and (max-width: 1024px) and (min-width: 425px) {
    .sb {
      width: 22%; } }
  @media screen and (max-width: 375px) {
    .sb {
      width: 20%; } }

.seb {
  width: 11px; }

.container-fluid {
  margin-left: 30px;
  margin-top: 40px; }

@media (max-width: 475px) {
  .container-fluid {
    margin-left: 9px;
    margin-top: 4px; } }

@media (max-width: 767px) {
  .hidden {
    display: none; }
  .ph-item {
    border: none; } }

.divider {
  border-right: 3px solid #ffffff;
  height: 100% !important; }

#shell-showcase {
  padding: 0; }
  @media screen and (min-width: 1280px) {
    #shell-showcase {
      height: 200px; } }
  @media screen and (max-width: 1279px) {
    #shell-showcase {
      height: 170px; } }

#shell-showcase-item {
  padding: 0;
  height: 100%; }

#shell-ph-row {
  height: 100%;
  margin: 0px; }
  @media screen and (max-width: 425px) {
    #shell-ph-row .hide-xs {
      display: none; }
    #shell-ph-row .wd-full {
      flex: 100% 1;
      display: inline-block; } }

.profile-loader-wrapper {
  width: 100%;
  max-width: 290px;
  height: 250px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  padding: 20px;
  margin: 0px; }

.profile-row-1 {
  width: 100%;
  height: 76px;
  display: unset;
  padding: 0px; }

.profile-img-loader {
  width: 48px;
  height: 48px;
  vertical-align: top;
  display: inline-block;
  border: solid 1px #e8effa;
  border-radius: 50%;
  background-color: #e3e9f0;
  box-shadow: 0 0 0 4px #ffffff inset;
  margin-right: 10px; }

.profile-content-loader {
  display: inline-block;
  width: 0px; }

.profile-detail-1 {
  max-width: 95px;
  width: 7.3vw;
  margin-bottom: 6px;
  height: 13px;
  border-radius: 9px;
  background-color: #dce3ed;
  display: inline-block; }

.profile-detail-2 {
  max-width: 145px;
  width: 11.09vw;
  height: 8px;
  margin-bottom: 3px;
  display: inline-block;
  border-radius: 9px;
  background-color: #e3e9f0; }

.profile-detail-3 {
  max-width: 125px;
  width: 9.55vw;
  display: inline-block;
  height: 5px;
  border-radius: 3px;
  background-color: #dce3ed; }

.profile-detail-4 {
  max-width: 35px;
  width: 2.68vw;
  height: 8px;
  border-radius: 9px;
  background-color: #e3e9f0;
  margin-top: 12px;
  margin-left: 14px;
  float: right; }

.profile-row-2 {
  width: 100%;
  height: 74px;
  border-radius: 8px;
  background-color: #f2f5fa;
  display: inline-block;
  margin-top: -20px;
  padding: 5px 0px 5px 5px; }
  .profile-row-2 span {
    width: 78px;
    height: 64px;
    display: inline-block;
    border-radius: 8px; }
    .profile-row-2 span .circle-loader {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      margin-top: 13px;
      margin-left: 29px;
      margin-bottom: 11px;
      background-color: #dce3ed; }
    .profile-row-2 span .line-loader {
      width: 59px;
      height: 8px;
      border-radius: 9px;
      margin-left: 10px;
      background-color: #e3e9f0; }
    @media (max-width: 1040px) {
      .profile-row-2 span {
        width: 90px; } }
  .profile-row-2 a {
    width: 1px;
    height: 60px;
    border-radius: 8px;
    display: inline-block;
    background-color: #e1e8f2; }
  .profile-row-2 .bg-white {
    background-color: #ffffff; }
  @media (max-width: 1040px) {
    .profile-row-2 .dis-none {
      display: none; } }

.profile-row-3 {
  width: 100%;
  height: 40px;
  margin-bottom: -20px;
  display: inline-block;
  border-radius: 3px;
  border: solid 1px #fbf0dd;
  background-color: #fffcf7;
  background: repeating-linear-gradient(-60deg, #fbf0dd, #fffcf7 2px, #fffcf7 8px, #fbf0dd 0); }
  .profile-row-3 .detail-row-1 {
    width: 125px;
    height: 13px;
    border-radius: 9px;
    background-color: #dce3ed;
    margin-top: 14px;
    margin-left: 12px;
    display: inline-block;
    margin-bottom: 13px; }
  .profile-row-3 .detail-row-2 {
    width: 45px;
    height: 8px;
    margin: 16px 13px;
    border-radius: 9px;
    float: right;
    background-color: #dce3ed; }

.ph-item:before {
  -webkit-animation: phAnimation 2s linear infinite;
          animation: phAnimation 2s linear infinite; }

#fallback-container {
  height: 100vh;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffaf0;
  position: relative; }
  #fallback-container .tech-logo {
    height: 40px;
    position: absolute;
    left: 40px;
    top: 40px;
    cursor: pointer; }
  #fallback-container .illustration {
    max-width: 482px;
    min-height: 450px;
    width: 100%;
    height: 100%;
    margin: 0 18.2px 13px 17.1px;
    padding: 1.8px 3.5px 38.9px 1.3px;
    object-fit: contain; }
  #fallback-container .text-container {
    font-family: Nunito Sans;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    text-align: center; }
    #fallback-container .text-container > div:first-child {
      margin: 0 0 10px 0;
      font-size: 48px;
      font-weight: 800;
      color: #e9630c; }
    #fallback-container .text-container > div:nth-child(n+2) {
      font-size: 20px; }
    #fallback-container .text-container > div:nth-child(2n+2) {
      margin: 10px 0;
      font-weight: 600; }
  #fallback-container .button-wrapper {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px; }
    @media (max-width: 425px) {
      #fallback-container .button-wrapper {
        flex-direction: column; } }
  #fallback-container > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }

.icon-fallback-img {
  display: inline-block;
  vertical-align: top;
  margin-top: -2px;
  margin-left: -2px;
  width: 100px;
  height: 100px;
  border-radius: 10px;
  padding: 20px 10px;
  color: #fff;
  position: absolute;
  font-size: 56px;
  background-color: #f2998f; }

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9000px;
  margin-left: 500px;
  background-color: white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #b4b9d8;
  color: #b4b9d8;
  box-shadow: 9000px 0 0 -5px #b4b9d8;
  -webkit-animation: dotPulse 1.5s infinite linear;
          animation: dotPulse 1.5s infinite linear;
  -webkit-animation-delay: .25s;
          animation-delay: .25s; }
  .dot-pulse::before, .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #b4b9d8;
    color: #b4b9d8; }
  .dot-pulse::before {
    box-shadow: 8985px 0 0 -5px #b4b9d8;
    -webkit-animation: dotPulseBefore 1.5s infinite linear;
            animation: dotPulseBefore 1.5s infinite linear;
    -webkit-animation-delay: 0s;
            animation-delay: 0s; }
  .dot-pulse::after {
    box-shadow: 9015px 0 0 -5px #b4b9d8;
    -webkit-animation: dotPulseAfter 1.5s infinite linear;
            animation: dotPulseAfter 1.5s infinite linear;
    -webkit-animation-delay: .5s;
            animation-delay: .5s; }

@-webkit-keyframes dotPulseBefore {
  0% {
    box-shadow: 8985px 0 0 -5px #b4b9d8; }
  30% {
    box-shadow: 8985px 0 0 2px #b4b9d8; }
  60%,
  100% {
    box-shadow: 8985px 0 0 -5px #b4b9d8; } }

@keyframes dotPulseBefore {
  0% {
    box-shadow: 8985px 0 0 -5px #b4b9d8; }
  30% {
    box-shadow: 8985px 0 0 2px #b4b9d8; }
  60%,
  100% {
    box-shadow: 8985px 0 0 -5px #b4b9d8; } }

@-webkit-keyframes dotPulse {
  0% {
    box-shadow: 9000px 0 0 -5px #b4b9d8; }
  30% {
    box-shadow: 9000px 0 0 2px #b4b9d8; }
  60%,
  100% {
    box-shadow: 9000px 0 0 -5px #b4b9d8; } }

@keyframes dotPulse {
  0% {
    box-shadow: 9000px 0 0 -5px #b4b9d8; }
  30% {
    box-shadow: 9000px 0 0 2px #b4b9d8; }
  60%,
  100% {
    box-shadow: 9000px 0 0 -5px #b4b9d8; } }

@-webkit-keyframes dotPulseAfter {
  0% {
    box-shadow: 9015px 0 0 -5px #b4b9d8; }
  30% {
    box-shadow: 9015px 0 0 2px #b4b9d8; }
  60%,
  100% {
    box-shadow: 9015px 0 0 -5px #b4b9d8; } }

@keyframes dotPulseAfter {
  0% {
    box-shadow: 9015px 0 0 -5px #b4b9d8; }
  30% {
    box-shadow: 9015px 0 0 2px #b4b9d8; }
  60%,
  100% {
    box-shadow: 9015px 0 0 -5px #b4b9d8; } }

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center; }

.spinner > div {
  width: 13px;
  height: 13px;
  background-color: #FFF;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both; }

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

